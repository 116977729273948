import React, { Component } from 'react';

import { Col} from 'react-bootstrap'

class DirectorProfile extends Component {
  render() {
    return (
            <Col xs={12} lg={3} className="p-2 profile text-center">
                <img src={this.props.image}/>
                <h3>{this.props.pName}</h3>
                <h4>{this.props.pType}</h4>
            </Col>

    );
  }
}

export default DirectorProfile;
