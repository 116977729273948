import React, { Component } from 'react';

import { Row, Col, Container} from 'react-bootstrap'

import DirectorProfile from './DirectorProfile'

import  WilfredoImage from '../../Assets/profile photos/Wilfredo Keng.png'
import  EmilioImage from '../../Assets/profile photos/Emilio Tiu.png'

class ED extends Component {
  render() {
    return (
          
        <Container fluid className="justify-content-center">
            <Row className="m-5 h-25 justify-content-center">
                <DirectorProfile image={WilfredoImage} pName="Wilfredo D. Keng" pType="Chairman" />
                <DirectorProfile image={EmilioImage} pName="Emilio Tiu" pType="Director" />
                <DirectorProfile image={WilfredoImage} pName="Wilfredo D. Keng" pType="Chairman" />
                <DirectorProfile image={EmilioImage} pName="Emilio Tiu" pType="Director" />

                
            </Row>
        </Container>
    );
  }
}

export default ED;
