import React from 'react';
import { Switch, Route} from 'react-router-dom';

import LandingPage from './LandingPage';
import PressReleasesPage from './PressReleasesPage';
import CSRPage from './CSRPage';
import ProjectsPage from './ProjectsPage';
import AboutUsPage from './AboutUsPage';
import ContactPage from './ContactPage';
import CompaniesPage from './CompaniesPage';
import Article from './Article'

const Main = () =>(

    <Switch>
        <Route exact path="/" component={LandingPage}/>
        <Route exact path="/press" component={PressReleasesPage}/>
        <Route exact path="/csr" component={CSRPage}/>
        <Route exact path="/projects" component={ProjectsPage}/>
        <Route exact path="/contact" component={ContactPage}/>
        <Route exact path="/aboutus" component={AboutUsPage}/>
        <Route exact path="/companies" component={CompaniesPage}/>
        <Route exact path="/article" component={Article}/>
    </Switch>
)

export default Main;