import React, { Component } from 'react';
import './css/CSRPage.css';

import { Row, Col, Container, Card, Image, Button } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome';
    

import {ReactComponent as Plant1} from '../Assets/svg/plant 1.svg';
import {ReactComponent as Plant2} from '../Assets/svg/plant 2.svg';
import {ReactComponent as Plant3} from '../Assets/svg/plant 3.svg';

import Logo from '../Assets/MainLogo.png';


import ReactFullpage from '@fullpage/react-fullpage';

import CSRFullPage from './FullPage/CSRFullPage'


class CSRPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nextPage:"#page1",
      scrollIcon:<FontAwesome name='angle-down'/>,
      plantClass:"plant show-1"
    };

    this.handleScroll =this.handleScroll.bind(this);
    
  }

  handleScroll(value){

    
    this.setState({plantClass:"plant show-"+(value+1)})

    if(value+1 == 3){
      
      value=-1;
      this.setState({scrollIcon:<FontAwesome name='angle-up' />})
    }else
      this.setState({scrollIcon:<FontAwesome name='angle-down' />})

    this.setState({nextPage:"#page"+(value+1)})


  }

  

  

  render() {
    return (
          <div className="background-container csr">
            <div className="position-fixed float-top" >
              <Row>
                
                <a href="/" id="HomeLogoContainer">
                  <img src={Logo} alt="Logo" id="HomeLogo" />
                </a>
              </Row>
              <Row className="justify-content-center h-25">
                  <h1 className="page-title">Corporate Social Responsibility</h1>
              </Row>
                
            </div> 
            <div fluid className="h-100 plant-bg w-100">
                <div className="position-fixed float-bottom h-25 w-100 soil">
                </div>
                <div className="plant-container position-fixed">
                    <Plant1 id="plant1" className={this.state.plantClass}></Plant1>
                </div>
                <div className="plant-container position-fixed">
                    <Plant2 id="plant2" className={this.state.plantClass}></Plant2>
                </div>
                <div className="plant-container position-fixed">
                    <Plant3 id="plant3" className={this.state.plantClass}></Plant3>
                </div>
            </div>
            <Container id="clickable-scroll" fluid className="position-fixed float-bottom">
                
              <Row className="justify-content-center">
                <a id="scroll-btn" href={this.state.nextPage}>
                  {this.state.scrollIcon}
                </a>
              </Row>
            </Container>
            <Container fluid id="scroll-part">
              <Row >
                <Col lg={1}>
                </Col>
                
                <Col lg={6} xs={12} >
                  <CSRFullPage cb={this.handleScroll}/>
                  
                </Col>
              </Row>
            </Container>
          </div>
    );
  }
}

export default CSRPage;


