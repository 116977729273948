import React, { Component } from 'react';
import './css/PressReleasesPage.css';

import { Row, Col, Container, Card, Image } from 'react-bootstrap'

import Logo from '../Assets/MainLogo.png';

class PressReleasesPage extends Component {
  render() {
    return (
          <div className="background-container press">
            <Row>
              
              <a href="/" id="HomeLogoContainer">
                <img src={Logo} alt="Logo" id="HomeLogo" />
              </a>
            </Row>
            <Row className="justify-content-center">
                <h1 className="page-title">Press Releases</h1>
            </Row>
            <Container fluid className="p-5">
              <Row>
                <Col xs={12} lg={8}  className="content-bg-dark mb-4">
                  <Card className="h-100">
                    <Row className="h-100">
                      <Col xs={12} lg={6} className="p-0">
                        <div className="square-container">
                          <a href="/article">
                            <Image className="square-image" src={require('../Assets/photos/PSE bnw.jpg')}/>
                          </a>
                        </div>
                      </Col>
                      
                      <Col>
                        <Container className="p-5 h-100 vertical-center-2">
                        
                          <a href="/article">

                            <h2>
                              PSE<br/>
                              Approve Listing
                              <br/>
                            </h2>
                            
                            <h1 className="text-white">
                              &#x27F6;
                            </h1>
                        
                          </a>
                        </Container>
                          
                      </Col>
                    </Row>
                  </Card>
                </Col>
                
                <Col xs={12} lg={4} className="content-bg-dark mb-4">
                  <Card  className="h-100">
                    <Row className="h-100">
                      <Col xs={12} className="p-0">
                        <div className="square-half">
                          <a href="/article">
                            <Image className="square-image" src={require('../Assets/photos/stock market bnw.jpg')}/>
                          </a>
                        </div>
                      </Col>
                      <Col>
                        
                      <Container className="p-5 h-100 vertical-center-2">
                          <a href="/article">
                            <h2>
                              CP Listed on<br/>
                              Phil. Stock Exchange
                              <br/>
                            </h2>
                            <h1 className="text-white">
                              &#x27F6;
                            </h1>
                          </a>
                        </Container>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
              <Col xs={12} lg={8}  className="content-bg-dark mb-4">
                  <Card className="h-100">
                    <Row className="h-100">
                      <Col xs={12} lg={6} className="p-0">
                        <div className="square-container">
                          <a href="/article">
                            <Image className="square-image" src={require('../Assets/photos/video thumbnail.png')}/>
                          </a>
                        </div>
                      </Col>
                      
                      <Col>
                        <Container className="p-5 h-100 vertical-center-2">
                        
                          <a href="/article">

                            <h2>
                              CP<br/>
                              Subsdiary
                              <br/>
                            </h2>
                            
                            <h1 className="text-white">
                              &#x27F6;
                            </h1>
                        
                          </a>
                        </Container>
                          
                      </Col>
                    </Row>
                  </Card>
                </Col>
                
                <Col xs={12} lg={4} className="content-bg-dark mb-4">
                  <Card  className="h-100">
                    <Row className="h-100">
                      <Col xs={12} className="p-0">
                        <div className="square-half">
                          <a href="/article">
                            <Image className="square-image" src={require('../Assets/photos/buildings_bw.jpg')}/>
                          </a>
                        </div>
                      </Col>
                      <Col>
                        
                      <Container className="p-5 h-100 vertical-center-2">
                          <a href="/article">
                            <h2>
                              CP Closer to<br/>
                              Official Listing
                              <br/>
                            </h2>
                            <h1 className="text-white">
                              &#x27F6;
                            </h1>
                          </a>
                        </Container>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              
            </Container>
          </div>
    );
  }
}

export default PressReleasesPage;
