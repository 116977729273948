import React, { Component } from 'react';
import './css/CompaniesPage.css';

import {Row, Col, Container, Image, Table} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome';


import {ReactComponent as CirclesBG} from '../Assets/svg/Companies-BG.svg';
import CompanyItem from './CompaniesComponents/CompanyItem'


import Logo from '../Assets/MainLogo.png';


import TableItem from './ProjectsComponents/TableItem'



class CompaniesPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      company:0,
      old:null,
      MainTextClass:"hidden",
      MainTextText:"a",
      width: window.innerWidth
    };

    this.handleHover =this.handleHover.bind(this);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleHover(value,item){

    this.setState({MainTextClass:""})

    if(this.state.old != null){
      this.state.old.setState({lit:false});
    }

    this.setState({old:item});
    
    item.setState({lit:true});

    this.setState({company:value});

    this.setState({MainTextText:item.props.children});
    
  }


  

  render() {
    const { width } = this.state;
    const isMobile = width <= 992;

    let companyItems

    if(isMobile){
      companyItems=(
        <div>
        <CompanyItem mobile={isMobile} className="light" left={45} top={36.3} title="Century Peak" desc="Mineral Development Corporation" cIndex={1} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor1
        </CompanyItem>
        <CompanyItem mobile={isMobile} left={34.1} top={50} title="Century Hua Guang" desc="Smelting Incorporated" cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor2
        </CompanyItem>
        <CompanyItem mobile={isMobile} left={37.5} top={41} title="Century Sidewide" desc="Smelting Incorporated" cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor3
        </CompanyItem>
        <CompanyItem mobile={isMobile} left={37} top={60} title="Century Summit" desc="Carrier Inc." cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor4
        </CompanyItem>
        <CompanyItem mobile={isMobile} left={44} top={65} title="Century Peak" desc="Cement Manufacturing" cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor5
        </CompanyItem>
      </div>
      )
    }
    else{
      companyItems=(
      <div>
        <CompanyItem className="light" left={34.1} top={50} title="Century Peak" desc="Mineral Development Corporation" cIndex={1} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor1
        </CompanyItem>
        <CompanyItem left={37} top={60} title="Century Hua Guang" desc="Smelting Incorporated" cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor2
        </CompanyItem>
        <CompanyItem left={60} top={61.5} title="Century Sidewide" desc="Smelting Incorporated" cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor3
        </CompanyItem>
        <CompanyItem left={64} top={53.5} title="Century Summit" desc="Carrier Inc." cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor4
        </CompanyItem>
        <CompanyItem left={63.2} top={45.5} title="Century Peak" desc="Cement Manufacturing" cIndex={2} cb={this.handleHover}>
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, eiusmod tempor5
        </CompanyItem>
      </div>
      )
    }
    
    return (
          <div className="background-container companies">
            <div id="titles" className="position-fixed float-top" >
              <Row>
              
                <a href="/" id="HomeLogoContainer">
                  <img src={Logo} alt="Logo" id="HomeLogo" />
                </a>
              </Row>
              <Row className="justify-content-center h-25">
                  <h1 className="page-title" >Our Companies</h1>
              </Row>
              
            </div> 
            <Container fluid  id="contents" className="position-fixed h-100  w-100">
              <Row className="h-100">
                <Col lg={12} id="SVG-Container" className="">
                  <div class="SVG-Interact">
                  
                    <div id="MainText" className={"vertical-center-3 "+this.state.MainTextClass}>
                      <h4 className="w-100">
                        {this.state.MainTextText}
                        <br/>
                        <br/>
                        <button className="btn-circle">
                          <p className="noselect">🡒</p>
                        </button>
                      </h4>
                    </div>
                      
                      {companyItems}
                    
                    <CirclesBG id="SVG-Circles" >
                    </CirclesBG>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
    );
  }
}

export default CompaniesPage;


