import React, { Component } from 'react';

import {Row, Col, Button} from 'react-bootstrap'

import FontAwesome from 'react-fontawesome'

import NewID from '../../utils/NewID'

class TableItem extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
    this.id = NewID("TableItem");
  }

  componentWillMount() {
    
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  render() {
    const { width } = this.state;
    const isMobile = width <= 992;

    let tableitems

    if(isMobile){
      tableitems=(
        <td className="TableItemMobile">
        
        <a data-toggle="collapse" data-target={"#"+this.id}>
          <Row>
            <Col xs={11} className="p-0"><h5>{this.props.Loc1}<br/>{this.props.Loc2}</h5></Col>
            <Col xs={1} className="p-0 text-center"><FontAwesome name='angle-down' /></Col>
            
          </Row>
          </a>
          <div id={this.id} class="collapse accordion-table">
            <Row className="p-0 text-center">
              <Col xs={5} className="p-0">{this.props.Num}</Col>
              
              <Col xs={4} className="p-0">{this.props.Com}</Col>
              
              <Col xs={3} className="p-0">{this.props.Area}</Col>

              
              <Col xs={5} className=" subtext">Registration No.</Col>
              
              <Col xs={4} className=" subtext">Commodity</Col>
              
              <Col xs={3} className=" subtext">Area in Hectares</Col>
            </Row>
          </div>
        </td>
      )
    }
    else{
      tableitems=(
        <>
        <td>{this.props.Loc1}<br/>{this.props.Loc2}</td>
        <td>{this.props.Num}</td>
        <td>{this.props.Com}</td>
        <td>{this.props.Area}</td>
        </>
      )
    }
    return (
        
        <tr>
            {tableitems}
        </tr>

       
    );
  }
}

export default TableItem;
