import React, { Component } from 'react';
import './css/LandingPage.css';

import Logo from '../Assets/MainLogo.png';

import FontAwesome from "react-fontawesome"

import ReactPlayer from 'react-player'


class LandingPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      videoOpen:false
    }
  }
  toggleVid(){
    this.setState({videoOpen:!this.state.videoOpen});
    
  }

  render() {
    return (
          <div className="background-container landing">
            <div className=" vertical-center d-flex justify-content-center">
            
              <img src={Logo} alt="Logo" id="MainHomeLogo" />
              <div className="background-image-cover w-100 h-100 position-fixed">
              
              </div>
            </div>
            <div id="videoButton" onClick={() => this.toggleVid()}>
              <div className="thumbnail vertical-center-3">
                <h1 className="w-100 text-center mb-0"><FontAwesome name='play' /></h1>
              </div>
              <div className="vid-desc p-4">
                <h5 className="text-gold">Century Peak<br/>
                Corporate Video
                </h5>
                3:00
              </div>
            </div>
            <div id="VideoContainer" class={this.state.videoOpen?"":"hidden"}>
                  <div id="closeBtn" className="p-5" onClick={() => this.toggleVid()}>
                    <h3>
                      <FontAwesome name='times' />
                    </h3>
                  </div>
                  <ReactPlayer url='https://vimeo.com/249289370' className=" video-player" playing={this.state.videoOpen} />
            </div>
          </div>
    );
  }
}

export default LandingPage;
