    import React from 'react';
    import ReactDOM from 'react-dom';
    import ReactFullpage from '@fullpage/react-fullpage';


class CSRFullpage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            currentSection: 0
        };
    }
        
    onLeave(origin, destination, direction) {
        console.log("Leaving section " + origin.index);
        this.props.cb(destination.index);
    }
    afterLoad(origin, destination, direction) {
        console.log("After load: " + destination.index);
        
    }

    render(){
        return(

            
            <ReactFullpage
            anchors={["page0", "page1", "page2"]}
            onLeave={this.onLeave.bind(this)}
            afterLoad={this.afterLoad.bind(this)}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                        <div className="section">
                            <h2>Environmental Engagement<br/></h2>
                            
                            <p>The year in review, CPC in its desire to adhere to sustainable development through responsible mining, has developed a drainage system that would mitigate soil erosion even before the mining operation starts. Canals were constructed along roadways with settling ponds at strategic locations to trap silt. Downstream, series of settling ponds are constructed to allow the silt to settle and prevent it from flowing into the natural waterways. The canals and settling ponds are maintained periodically to be effective at all times. Moreover, the roadways are crowned so that surface water runoffs flow towards the canals.</p>
                            
                           
                        </div>
                        <div className="section">
                            <p>In line with Company’s commitment to support the three year National Greening Program (NGP) of the government pursuant to Executive Order no. 26 issued on February 24, 2011, was enjoined to implement the NGP to help regain the country’s depleting forest cover and ensure sufficient supply for the future. Under this program, mining companies are required to either plant the trees or donate seedlings to People Organizations (PO) of choice for the PO to plant the seedlings outside of the mining area.</p>
                        </div>
                        
                        <div className="section">
                            <p>CPC chose to construct its own nursery and gathered seedlings of trees endemic to the locality, such are: 2,600 seedlings of magkono, 400 seedlings of pine tree, and 180 seedlings of madre de cacao. A total of 500 seedlings of which 120 seedlings of magkono, 200 seedlings of pine tree, and 180 seedlings of madre de cacao were planted during the year.</p>
                        </div>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
            );
    }
    } 

export default CSRFullpage;