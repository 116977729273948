
import React, { Component } from 'react';

import {OverlayTrigger, Popover} from 'react-bootstrap'

class MapCircle extends Component {
    
  render() {
    
    return (
          
        <div className="map-circle" style={this.props.style}>
            <OverlayTrigger overlay={
                <Popover id="popover-contained" >
                <span className="text-center"><h5>{this.props.title}</h5> {this.props.desc}</span>
                </Popover>}>
                <div className="h-100 w-100">
                </div>
            </OverlayTrigger>
        </div>
    );
  }
}

export default MapCircle;
