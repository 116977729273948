import React, { Component } from 'react';
import './css/AboutUsPage.css';

import { Row, Col, Container, Card, Image,Button } from 'react-bootstrap'

import FontAwesome from 'react-fontawesome'

import Logo from '../Assets/MainLogo.png';

import CI from './AboutUsComponents/CO'
import BOD from './AboutUsComponents/BOD'
import ED from './AboutUsComponents/ED'

class AboutUsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Corporate Information",
      fading: false,
      contentNum:0,
      contentOut: "",
      hide: "",
      contentHide:"hide"
    };
  }

  toggleTab=(title, isMain,tab)=>{

    if(!isMain){
      this.setState({hide: ""});
      this.setState({contentHide:"hide"});
    }else{
      this.setState({hide: "hide"});
      this.setState({contentHide: ""});

      this.setState({contentNum:tab});
      switch(tab){
        case 0: this.setState({contentOut: <CI/>});break;
        case 1: this.setState({contentOut: <BOD/>});break;
        case 2: this.setState({contentOut: <ED/>});
      }


    }

    this.setState({fading: true}); // fade out
    this.timer = setTimeout(_ => {
    this.setState({title: title}); // swap the text
    this.setState({fading: false}); // fade back in
    }, 500);

  }

  render() {
    return (
          <div className="background-container aboutus">
            <Row>              
              <a href="/" id="HomeLogoContainer">
                <img src={Logo} alt="Logo" id="HomeLogo" />
              </a>
            </Row>
            <Row className="justify-content-center">
                <h1 className={`${this.state.fading ? 'page-title faded' : 'page-title'}`}>{this.state.title}</h1>
            </Row>
            <Container fluid id="Menu" className={this.state.hide} >
              <Row className="justify-content-center">
                <Col xs={12} lg={4}  className="mb-5">
                  <h1>Company<br/>Profile</h1>
                  
                  <div className="h-line">
                  </div>
                  <div className=" mt-5 float-right pr-5">
                    <button onClick={()=>this.toggleTab("Company Profile",true,0)} className="btn-circle">
                      <p className="noselect">🡒</p>
                    </button>
                    Click here to find out more
                  </div>
                </Col>
                <Col xs={12} lg={4}  className="mb-5">
                  <h1>Board of<br/>Directors</h1>
                  
                  <div className="h-line">
                  </div>
                  <div className=" mt-5 float-right pr-5">
                    <button onClick={()=>this.toggleTab("Board of Directors",true,1)} className="btn-circle">
                      <p className="noselect">🡒</p>
                    </button>
                    Click here to find out more
                  </div>
                </Col>   
                <Col xs={12} lg={4}  className="mb-5">
                <h1>Executuve<br/>Directors</h1>
                  
                  <div className="h-line">
                  </div>  
                  <div className=" mt-5 float-right pr-5">
                    <button onClick={()=>this.toggleTab("Executuve Director",true,2)} className="btn-circle">
                      <p className="noselect">🡒</p>
                    </button>
                    Click here to find out more
                  </div>
                </Col>                
              </Row>
            </Container>
            <div id="Content-Container" className={this.state.contentHide}>
              <div className={"h-100 w-100 "+ (this.state.contentNum==0 ? 'overflow-x-content' : 'overflow-y-content') }>
               {this.state.contentOut}
              </div>
              <button id="back-btn" onClick={()=>this.toggleTab("Company Information",false)} className="btn-circle">
                <p className="noselect">🡑</p>
              </button>
            </div>
            <div className="background-img">
            </div>
          </div>
          
    );
  }
}

export default AboutUsPage;
