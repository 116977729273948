import React, { Component } from 'react';
import './css/Navigation.css';

import { Nav, Container,Row } from 'react-bootstrap'  

import FontAwesome from 'react-fontawesome'

import Logo from '../Assets/MainLogo.png';
import {ReactComponent as Arrow} from '../Assets/svg/ArrowUp.svg';


class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      icon: <FontAwesome name='bars' />,
      show: true,
      navClass: [" "]
    };
  }

  toggleNav(){
    this.setState({show: !this.state.show})
    if(this.state.show){
      this.setState({icon: <FontAwesome name='times' /> })
      this.setState({navClass: 'show'})
    }
    else{
      this.setState({icon: <FontAwesome name='bars' /> });
      this.setState({navClass: ' '})
    }
  }


  
  render() {
    return (
        
        <div className="Nav-Stuff">
          <div id="Nav-Hamburger" className="position-fixed flex-column h-100" >
            <div className="vertical-center-2" onClick={() => this.toggleNav()}>
              <div className="text-center w-100">
                <h3>
                 {this.state.icon}
                </h3>
              </div>
            </div>
                
            
          </div>
          <div id="NavContainer" className={this.state.navClass}>
          
           
            <Nav className="flex-column position-fixed h-100 w-100 vertical-center" id="NavMenu" >
            
            
              <Arrow id="BackArrow" className=" position-fixed"/>
              
              
              <a href="/">
                <img src={Logo} alt="Logo" id="HomeLogo" className="position-fixed"/>
              </a>
              <Container className="vertical-center w-100" id="NavMenu-Inner">
                <h1>
                  <Nav.Link eventKey="link-1" href="aboutus" >CORPORATE INFORMATION</Nav.Link>
                  <br/>
                  <Nav.Link eventKey="link-2" href="companies" >OUR COMPANIES</Nav.Link>
                  <br/>
                  <Nav.Link eventKey="link-3" href="press" >PRESS RELEASES</Nav.Link>
                  <br/>
                  <Nav.Link eventKey="link-4" href="projects" >CURRENT PROJECTS</Nav.Link>
                  <br/>
                  <Nav.Link eventKey="link-5" href="csr" >CORPORATE SOCIAL RESPONSIBILITIES</Nav.Link>
                  <br/>
                  <Nav.Link eventKey="link-6" href="contact" >GET IN TOUCH</Nav.Link>
                </h1>
              </Container>
              
            </Nav>
            
          
          </div>
          
        </div>

        
     
        
          

        
    );
  }
}


export default Navigation;
