import React, { Component } from 'react';
import './css/ContactPage.css';

import { Row, Col, Container, Form } from 'react-bootstrap'

import Logo from '../Assets/MainLogo.png';

class PressReleasesPage extends Component {
  render() {
    return (
          <div className="background-container contact">
            <Row>
              
              <a href="/" id="HomeLogoContainer">
                <img src={Logo} alt="Logo" id="HomeLogo" />
              </a>
            </Row>
            <Container id="Main-Content" fluid className="p-5">
              <Row id="Row-Content" className="pl-5 pr-5 justify-content-center">
                <Col xs={12} lg={5} id="ContactInfo" className="p-5">
                  <h2 className="text-gold">
                  Century Peak<br/> Holding Corporation
                  </h2>
                  <br/><br/>
                  <p>
                  Units 1403 & 1404 Equitable Bank Tower Condominium <br/>
                  8751 Paseo De Roxas, Salcedo Village, Makati City. Philippines<br/>
                  </p>
                  <p>
                  Trunk Line : (+632) 856-0999<br/>
                  Fax No.: (+632) 856-4844<br/>
                  Email: info@centurypeakmetals.com<br/>
                  Email: dan_antonio@yahoo.com
                  </p>
                </Col>
                
                <Col xs={12} lg={5}  className="p-5">
                  <h2>
                    Get in touch:
                  </h2>
                  <br/>
                  <br/>
                  <Form>
                    <Form.Group controlId="formName">
                      <Form.Control type="name" placeholder="Name" />
                    </Form.Group>
                    <br/>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="Email" autofill="off"/>
                    </Form.Group>
                    <br/>
                    <Form.Group controlId="formSubject">
                      <Form.Control as="select">
                        <option>Subject</option>
                        <option>Jobs</option>
                        <option>Else</option>
                      </Form.Control>
                    </Form.Group>
                    <br/><br/><br/>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control as="textarea" placeholder="Inquiry"/>
                    </Form.Group>
                    <Form.Group controlId="fromUpload">
                      <button type="button" className="btn-circle">
                        <p className="noselect">+</p>
                      </button> Add attachment 
                    </Form.Group>
                    
                    <Form.Group controlId="submit" className="text-right">
                      Send
                      <button type="button" className="btn-circle">
                        <p className="noselect">🡒</p>
                      </button> 
                    </Form.Group>
                  </Form>
                </Col>

                <Col xs={12} lg={5} id="ContactInfoMobile" className="p-5">
                  <h2>
                  Century Peak<br/> Holding Corporation
                  </h2>
                  <br/><br/>
                  <p>
                  Units 1403 & 1404 Equitable Bank Tower Condominium <br/>
                  8751 Paseo De Roxas, Salcedo Village, Makati City. Philippines<br/>
                  </p>
                  <p>
                  Trunk Line : (+632) 856-0999<br/>
                  Fax No.: (+632) 856-4844<br/>
                  Email: info@centurypeakmetals.com<br/>
                  Email: dan_antonio@yahoo.com
                  </p>
                </Col>
              </Row>

              
              
            </Container>
          </div>
    );
  }
}

export default PressReleasesPage;
