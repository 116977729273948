
import React, { Component } from 'react';


class CompanyItem extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
      lit:false,
    };
  }


  

  getLitClass(){
    if(this.state.lit)
      return "light";
    else
      return ""; 
  }

  clickStyle() {
    return {left:this.props.left+"%", top:this.props.top+"%"}
  }

  
  setLight=(light)=>{
    this.props.cb( this.props.cIndex,this);
  }

  textStyle(){
    var out = {};
    if(!this.props.mobile){
      if(this.props.left < 50)
        out.right = (101-this.props.left)+"%"
      else
        out.left = (this.props.left+1)+"%"
    }else{
      out.right = (96-this.props.left)+"%"
    }

    

    if(this.props.top <=  50)
      out.bottom = (100-this.props.top)+"%"
      else
      out.top = (this.props.top+2)+"%"
      
    return out;
  }

  render() {

    return (
        <div className={this.getLitClass()}>
        <div className="svg-click" style={this.clickStyle()} onMouseEnter={() => this.setLight(true)}></div>
        <h5 className="svg-text" style={this.textStyle()} onMouseEnter={() => this.setLight(true)}>
            {this.props.title}
            <br/>
            {this.props.desc}
        </h5>
        </div>       
    );
  }
}

export default CompanyItem;
