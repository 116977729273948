import React, { Component } from 'react';

import { Col} from 'react-bootstrap'

class TimelinePiece extends Component {
  render() {
    return (
            <Col lg={this.props.size ? this.props.size :6} xs={12} className="p-2 profile text-center h-75">
                <div className="h-50 m-5">
                    <h3>{this.props.children}</h3>
                    
                </div>
                <div className="linePart justify-content-center">    
                    {this.props.left!=false ? <div className="h-line-l"/>:""}
                    {this.props.right!=false ? <div className="h-line-r"/>:""}
                    {this.props.circle!=false ? <img className="circle"/>:""}
                    
                </div>
                <div className="h-25 m-5">
                    <h4>{this.props.date}</h4>
                    
                </div>
            </Col>

    );
  }
}

export default TimelinePiece;
