import React, { Component } from 'react';

import { Row, Col, Container} from 'react-bootstrap'

import TimelinePiece from './TimelinePiece'

class CO extends Component {
  render() {
    return (
          
        <Container fluid className="justify-content-center h-100">
            <Row id="timeline" className="m-5 justify-content-left flex-nowrap">
                
                <TimelinePiece date="December 30, 2003"  left={false}>
                    Century Peak Metals Holdings Corporation was registered with the Philippine Securities and Exchange Commission
                </TimelinePiece>
                <TimelinePiece date="February 15, 2018" >
                    SEC approved the change in the Company’s corporate name to Century Peak Metals Holdings Corporation
                </TimelinePiece>
                <TimelinePiece date="February 15, 2018" >
                    SEC approved the change in the Company’s corporate name to Century Peak Metals Holdings Corporation
                </TimelinePiece>
                <TimelinePiece date="February 15, 2018" >
                    SEC approved the change in the Company’s corporate name to Century Peak Metals Holdings Corporation
                </TimelinePiece>
                <TimelinePiece date="February 15, 2018" right={false}>
                    SEC approved the change in the Company’s corporate name to Century Peak Metals Holdings Corporation
                </TimelinePiece>
    

                
            </Row>
        </Container>
    );
  }
}

export default CO;
