import React, { Component } from 'react';
import './css/Article.css';

import { Row, Col, Container, Card, Image } from 'react-bootstrap'

import Logo from '../Assets//MainLogo.png';

class Article extends Component {
  render() {
    return (
          <div className="background-container article">
            <Row>
              
              <a href="/" id="HomeLogoContainer">
                <img src={Logo} alt="Logo" id="HomeLogo" />
              </a>
            </Row>
            <Row className="justify-content-center mb-5 pb-5">
                <h1 className="page-title">Press Releases</h1>
            </Row>
            <Container  className="mt-5">
              <h2 >PSE Approves Listing By Way of Introduction of Century Peak Metals Holdings Corp.</h2>
              <p>
                The Philippine Stock Exchange (PSE) recently approved the initial listing by way of 
                introduction of Century Peak Metals Holdings Corporation (CPMHC). CPMHC is the 
                holding firm of Century Peak Corp. (CPC), the nickel-mining company it fully acquired in 
                March 2008, and Century Hua Guang Smelting Inc., where it has a 55% stake. The actual 
                listing of CPMHC is yet to be scheduled depending on CPMHC’s compliance with the 
                post-approval requirements, including the SEC Permit to Sell Securities and the 
                Registration and Licensing Order.
              </p>
              <h2 >About Century Peak Metals Holdings Corporation</h2>
              <p>
                Century Peak Metals Holdings Corporation (formerly, Fil Hispano Corporation) was 
                incorporated on December 30, 2003 as an investment holding company. On February 
                15, 2008, the SEC approved the change of its corporate name to Century Peak Metals 
                Holdings Corporation. On April 14, 2008, the SEC further approved the amendment of the 
                Company’s Articles of Incorporation changing its primary purpose to promoting, operating, 
                managing, holding owning or investing in corporations or entities that are engaged in 
                mining activities or mining-related activities.
              </p>
            </Container>
          </div>
    );
  }
}

export default Article;
