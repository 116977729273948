import React, { Component } from 'react';
import './css/ProjectsPage.css';

import {Row, Col, Container, Image, Table} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome';


import {ReactComponent as PHSVG} from '../Assets/svg/Philippine Map.svg';
import MapCircle from './ProjectsComponents/MapCircle'


import Logo from '../Assets/MainLogo.png';


import TableItem from './ProjectsComponents/TableItem'



class ProjectsPage extends Component {

  handleHover(){
    alert("WOW");
  }
  

  render() {
    return (
          <div className="background-container projects">
            <div id="titles" className=" float-top" >
              <Row>
              
                <a href="/" id="HomeLogoContainer">
                  <img src={Logo} alt="Logo" id="HomeLogo" />
                </a>
              </Row>
              <div className="justify-content-center position-absolute w-100 no-select">
                  <h1 className="page-title" >CPMH Properties in the Philippines</h1>
              </div>
              
            </div> 
            <Container fluid  id="contents" className=" h-100  w-100">
              <Row className="h-100">
                <Col lg={4} id="PH-Map-Container" className="">
                  <div className="PH-Map-Interact">
                  
                    <MapCircle title="Zambales" desc="mining prospects" style={{left:'43%',top:'25%'}}/>
                    <MapCircle title="Zambales" desc="mining prospects" style={{left:'50%',top:'50%'}}/>
                    <MapCircle title="Zambales" desc="mining prospects" style={{left:'30%',top:'70%'}}/>
                    <MapCircle title="Zambales" desc="mining prospects" style={{left:'80%',top:'80%'}}/>
                    <MapCircle title="Zambales" desc="mining prospects" style={{left:'70%',top:'60%'}}/>

                    <PHSVG id="PH-Map" >
                      
                    </PHSVG>
                  </div>
                </Col>
                <Col id="Table-Main-Container" lg={8} className="p-0">
                  <Container fluid id="Table-Container">
                  <h5 className="text-center">Summary of CPC Active Projects / Prospects and Mineral Properties
                  </h5>
                    <div id="Table-Container-2">
                     
                      <Table responsive>
                        <thead className="bg-white">
                          <tr>
                            <th>Location</th>
                            <th>Registration No.</th>
                            <th>Commodity</th>
                            <th>Area in Hectares</th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableItem Loc1="Dinagat" Loc2="Esperanza Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel II" Com="Nickel, Chromite, Iron" Area="463.93"/>
                          <TableItem Loc1="Dinagat" Loc2="Cabinliw Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel I" Com="Nickel, Chromite, Iron" Area="772.47 "/>
                          <TableItem Loc1="Dinagat" Loc2="Libjo Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel III" Com="Nickel, Chromite, Iron" Area="1,935.69 "/>
                          <TableItem Loc1="Dinagat" Loc2="Esperanza Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel II" Com="Nickel, Chromite, Iron" Area="463.93"/>
                          <TableItem Loc1="Dinagat" Loc2="Cabinliw Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel I" Com="Nickel, Chromite, Iron" Area="772.47 "/>
                          <TableItem Loc1="Dinagat" Loc2="Libjo Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel III" Com="Nickel, Chromite, Iron" Area="1,935.69 "/>
                          <TableItem Loc1="Dinagat" Loc2="Esperanza Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel II" Com="Nickel, Chromite, Iron" Area="463.93"/>
                          <TableItem Loc1="Dinagat" Loc2="Cabinliw Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel I" Com="Nickel, Chromite, Iron" Area="772.47 "/>
                          <TableItem Loc1="Dinagat" Loc2="Libjo Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel III" Com="Nickel, Chromite, Iron" Area="1,935.69 "/>
                          <TableItem Loc1="Dinagat" Loc2="Esperanza Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel II" Com="Nickel, Chromite, Iron" Area="463.93"/>
                          <TableItem Loc1="Dinagat" Loc2="Cabinliw Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel I" Com="Nickel, Chromite, Iron" Area="772.47 "/>
                          <TableItem Loc1="Dinagat" Loc2="Libjo Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel III" Com="Nickel, Chromite, Iron" Area="1,935.69 "/>
                          <TableItem Loc1="Dinagat" Loc2="Esperanza Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel II" Com="Nickel, Chromite, Iron" Area="463.93"/>
                          <TableItem Loc1="Dinagat" Loc2="Cabinliw Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel I" Com="Nickel, Chromite, Iron" Area="772.47 "/>
                          <TableItem Loc1="Dinagat" Loc2="Libjo Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel III" Com="Nickel, Chromite, Iron" Area="1,935.69 "/>
                          <TableItem Loc1="Dinagat" Loc2="Esperanza Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel II" Com="Nickel, Chromite, Iron" Area="463.93"/>
                          <TableItem Loc1="Dinagat" Loc2="Cabinliw Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel I" Com="Nickel, Chromite, Iron" Area="772.47 "/>
                          <TableItem Loc1="Dinagat" Loc2="Libjo Nickel Project" Num = "MPSA 283-2009-XIII-SMR Parcel III" Com="Nickel, Chromite, Iron" Area="1,935.69 "/>
                        </tbody>
                      </Table>
                    </div>
                  </Container>
                </Col>
              </Row>
            </Container>

          </div>
    );
  }
}

export default ProjectsPage;


